<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <validation-observer ref="loginValidation">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
        enctype="multipart/form-data"
      >
        <div class="row">
          <!-- $store.state.base_url + 'images/CIT-13-Years.jpg' -->
          <div class="col-md-6">
            <b-form-group label="Page Title" label-for="title">
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
              >
                <b-form-input
                  id="title"
                  v-model="form.title"
                  :state="errors.length > 0 ? false : null"
                  name="title"
                  placeholder="Enter Your Page Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Page Description" label-for="description">
              <validation-provider
                #default="{ errors }"
                name="description"
                rules="required"
              >
                <b-form-textarea
                  id="description"
                  v-model="form.description"
                  :state="errors.length > 0 ? false : null"
                  name="description"
                  placeholder="Enter Your Page Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>

          <div class="col-md-6">
            <b-form-group label="Meta Title" label-for="meta_title">
              <validation-provider
                #default="{ errors }"
                name="meta_title"
                rules="required"
              >
                <b-form-input
                  id="meta_title"
                  v-model="form.meta_title"
                  :state="errors.length > 0 ? false : null"
                  name="meta_title"
                  placeholder="Enter Your Meta Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Meta Description" label-for="meta_description">
              <validation-provider
                #default="{ errors }"
                name="meta_description"
                rules="required"
              >
                <b-form-textarea
                  id="meta_description"
                  v-model="form.meta_description"
                  :state="errors.length > 0 ? false : null"
                  name="meta_description"
                  placeholder="Enter Your Meta Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <div class="border rounded p-1 mb-1">
              <div class="w-100 text-center">
                <b-img
                  :src="department_icon"
                  height="60"
                  width="60"
                  class="rounded mr-2 mb-1 mb-md-0 text-center"
                />
              </div>
              <b-form-group label-for="department_icon">
                <b-form-file
                  class="mt-1"
                  id="department_icon"
                  accept=".jpg, .png, .gif"
                  @change="departmentIcon($event)"
                  placeholder="Select Department Icon"
                  drop-placeholder="Drop file here..."
                />
              </b-form-group>
              <b-form-group
                label="Department Title"
                label-for="department_title"
              >
                <validation-provider
                  #default="{ errors }"
                  name="department_title"
                  rules="required"
                >
                  <b-form-input
                    id="department_title"
                    v-model="form.department_title"
                    :state="errors.length > 0 ? false : null"
                    name="department_title"
                    placeholder="Enter Your Department Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Department Number"
                label-for="department_qty"
              >
                <validation-provider
                  #default="{ errors }"
                  name="department_qty"
                  rules="required"
                >
                  <b-form-input
                    id="department_qty"
                    v-model="form.department_qty"
                    :state="errors.length > 0 ? false : null"
                    name="department_qty"
                    placeholder="Enter Your Department Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Department Color"
                label-for="department_color"
              >
                <validation-provider
                  #default="{ errors }"
                  name="department_color"
                  rules="required"
                >
                  <b-form-input
                    type="color"
                    id="department_color"
                    v-model="form.department_color"
                    :state="errors.length > 0 ? false : null"
                    name="department_color"
                    placeholder="Enter Your Department Color"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-4">
            <div class="border rounded p-1 mb-1">
              <div class="w-100 text-center">
                <b-img
                  :src="mentors_icon"
                  height="60"
                  width="60"
                  class="rounded mr-2 mb-1 mb-md-0 text-center"
                />
              </div>
              <b-form-group label-for="mentors_icon">
                <b-form-file
                  class="mt-1"
                  id="mentors_icon"
                  accept=".jpg, .png, .gif"
                  @change="mentorsIcon($event)"
                  placeholder="Select Mentors Icon"
                  drop-placeholder="Drop file here..."
                />
              </b-form-group>
              <b-form-group label="Mentors Title" label-for="mentors_title">
                <validation-provider
                  #default="{ errors }"
                  name="mentors_title"
                  rules="required"
                >
                  <b-form-input
                    id="mentors_title"
                    v-model="form.mentors_title"
                    :state="errors.length > 0 ? false : null"
                    name="mentors_title"
                    placeholder="Enter Your Mentors Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Mentors Number" label-for="mentors_qty">
                <validation-provider
                  #default="{ errors }"
                  name="mentors_qty"
                  rules="required"
                >
                  <b-form-input
                    id="mentors_qty"
                    v-model="form.mentors_qty"
                    :state="errors.length > 0 ? false : null"
                    name="mentors_qty"
                    placeholder="Enter Your Mentors Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Mentors Color" label-for="mentors_color">
                <validation-provider
                  #default="{ errors }"
                  name="mentors_color"
                  rules="required"
                >
                  <b-form-input
                    type="color"
                    id="mentors_color"
                    v-model="form.mentors_color"
                    :state="errors.length > 0 ? false : null"
                    name="mentors_color"
                    placeholder="Enter Your Mentors Color"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-4">
            <div class="border rounded p-1 mb-1">
              <div class="w-100 text-center">
                <b-img
                  :src="support_icon"
                  height="60"
                  width="60"
                  class="rounded mr-2 mb-1 mb-md-0 text-center"
                />
              </div>
              <b-form-group label-for="support_icon">
                <b-form-file
                  class="mt-1"
                  id="support_icon"
                  accept=".jpg, .png, .gif"
                  @change="supportIcon($event)"
                  placeholder="Select Support Icon"
                  drop-placeholder="Drop file here..."
                />
              </b-form-group>
              <b-form-group label="Support Title" label-for="support_title">
                <validation-provider
                  #default="{ errors }"
                  name="support_title"
                  rules="required"
                >
                  <b-form-input
                    id="support_title"
                    v-model="form.support_title"
                    :state="errors.length > 0 ? false : null"
                    name="support_title"
                    placeholder="Enter Your Support Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Support Number" label-for="support_qty">
                <validation-provider
                  #default="{ errors }"
                  name="support_qty"
                  rules="required"
                >
                  <b-form-input
                    id="support_qty"
                    v-model="form.support_qty"
                    :state="errors.length > 0 ? false : null"
                    name="support_qty"
                    placeholder="Enter Your Support Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Support Color" label-for="support_color">
                <validation-provider
                  #default="{ errors }"
                  name="support_color"
                  rules="required"
                >
                  <b-form-input
                    type="color"
                    id="support_color"
                    v-model="form.support_color"
                    :state="errors.length > 0 ? false : null"
                    name="support_color"
                    placeholder="Enter Your Support Color"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
        </div>
        <!-- submit buttons -->
        <b-button type="submit" variant="primary" @click="validationForm">
          Update
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
export default {
  props: ["marketplaces"],
  data() {
    return {
      form: {
        title: "",
        description: "",
        meta_title: "",
        meta_description: "",

        department_title: "",
        department_qty: "",
        department_color: "",
        mentors_title: "",
        mentors_qty: "",
        mentors_color: "",
        support_title: "",
        support_qty: "",
        support_color: "",

        department_icon: null,
        mentors_icon: null,
        support_icon: null,
      },
      department_icon: null,
      mentors_icon: null,
      support_icon: null,
      required,
      email,
    };
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    async getBanner() {
      const teacher = await this.callApi("get", "/app/teacher/page/show");
      if (teacher.status == 200) {
        this.form.title = teacher.data.teacher.title;
        this.form.description = teacher.data.teacher.description;
        this.form.meta_title = teacher.data.teacher.meta_title;
        this.form.meta_description = teacher.data.teacher.meta_description;

        this.department_icon =
          this.$store.state.base_url +
          (teacher.data.teacher.department_icon
            ? teacher.data.teacher.department_icon
            : "images/teacher_page/default-1.png");
        this.form.department_title = teacher.data.teacher.department_title;
        this.form.department_qty = teacher.data.teacher.department_qty;
        this.form.department_color = teacher.data.teacher.department_color;

        this.mentors_icon =
          this.$store.state.base_url +
          (teacher.data.teacher.mentors_icon
            ? teacher.data.teacher.mentors_icon
            : "images/teacher_page/default-2.png");
        this.form.mentors_title = teacher.data.teacher.mentors_title;
        this.form.mentors_qty = teacher.data.teacher.mentors_qty;
        this.form.mentors_color = teacher.data.teacher.mentors_color;

        this.support_icon =
          this.$store.state.base_url +
          (teacher.data.teacher.support_icon
            ? teacher.data.teacher.support_icon
            : "images/teacher_page/default-3.png");
        this.form.support_title = teacher.data.teacher.support_title;
        this.form.support_qty = teacher.data.teacher.support_qty;
        this.form.support_color = teacher.data.teacher.support_color;
      }
    },

    departmentIcon(e) {
      this.form.department_icon = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.department_icon = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    mentorsIcon(e) {
      this.form.mentors_icon = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.mentors_icon = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    supportIcon(e) {
      this.form.support_icon = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.support_icon = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    Update() {
      const fd = new FormData();
      if (this.form.department_icon) {
        fd.append(
          "department_icon",
          this.form.department_icon,
          this.form.department_icon.name
        );
      }

      if (this.form.mentors_icon) {
        fd.append(
          "mentors_icon",
          this.form.mentors_icon,
          this.form.mentors_icon.name
        );
      }

      if (this.form.support_icon) {
        fd.append(
          "support_icon",
          this.form.support_icon,
          this.form.support_icon.name
        );
      }

      fd.append("title", this.form.title);
      fd.append("description", this.form.description);
      fd.append("department_title", this.form.department_title);
      fd.append("department_qty", this.form.department_qty);
      fd.append("department_color", this.form.department_color);
      fd.append("mentors_title", this.form.mentors_title);
      fd.append("mentors_qty", this.form.mentors_qty);
      fd.append("mentors_color", this.form.mentors_color);
      fd.append("support_title", this.form.support_title);
      fd.append("support_qty", this.form.support_qty);
      fd.append("support_color", this.form.support_color);
      fd.append("meta_title", this.form.meta_title);
      fd.append("meta_description", this.form.meta_description);

      axios
        .post("/app/teacher/page/update", fd)
        .then((res) => {
          this.getBanner();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.title)
              this.e(e.response.data.errors.title[0]);
            if (e.response.data.errors.description)
              this.e(e.response.data.errors.description[0]);
            if (e.response.data.errors.meta_title)
              this.e(e.response.data.errors.meta_title[0]);
            if (e.response.data.errors.meta_description)
              this.e(e.response.data.errors.meta_description[0]);
            if (e.response.data.errors.department_icon)
              this.e(e.response.data.errors.department_icon[0]);
          }
        });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Update();
        }
      });
    },
  },

  components: {
    vSelect,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
};
</script>

<style></style>
