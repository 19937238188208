var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Page Title","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null,"name":"title","placeholder":"Enter Your Page Title"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Page Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"name":"description","placeholder":"Enter Your Page Description","rows":"4"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Meta Title","label-for":"meta_title"}},[_c('validation-provider',{attrs:{"name":"meta_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"meta_title","state":errors.length > 0 ? false : null,"name":"meta_title","placeholder":"Enter Your Meta Title"},model:{value:(_vm.form.meta_title),callback:function ($$v) {_vm.$set(_vm.form, "meta_title", $$v)},expression:"form.meta_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Meta Description","label-for":"meta_description"}},[_c('validation-provider',{attrs:{"name":"meta_description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"meta_description","state":errors.length > 0 ? false : null,"name":"meta_description","placeholder":"Enter Your Meta Description","rows":"4"},model:{value:(_vm.form.meta_description),callback:function ($$v) {_vm.$set(_vm.form, "meta_description", $$v)},expression:"form.meta_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"border rounded p-1 mb-1"},[_c('div',{staticClass:"w-100 text-center"},[_c('b-img',{staticClass:"rounded mr-2 mb-1 mb-md-0 text-center",attrs:{"src":_vm.department_icon,"height":"60","width":"60"}})],1),_c('b-form-group',{attrs:{"label-for":"department_icon"}},[_c('b-form-file',{staticClass:"mt-1",attrs:{"id":"department_icon","accept":".jpg, .png, .gif","placeholder":"Select Department Icon","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.departmentIcon($event)}}})],1),_c('b-form-group',{attrs:{"label":"Department Title","label-for":"department_title"}},[_c('validation-provider',{attrs:{"name":"department_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"department_title","state":errors.length > 0 ? false : null,"name":"department_title","placeholder":"Enter Your Department Title"},model:{value:(_vm.form.department_title),callback:function ($$v) {_vm.$set(_vm.form, "department_title", $$v)},expression:"form.department_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Department Number","label-for":"department_qty"}},[_c('validation-provider',{attrs:{"name":"department_qty","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"department_qty","state":errors.length > 0 ? false : null,"name":"department_qty","placeholder":"Enter Your Department Number"},model:{value:(_vm.form.department_qty),callback:function ($$v) {_vm.$set(_vm.form, "department_qty", $$v)},expression:"form.department_qty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Department Color","label-for":"department_color"}},[_c('validation-provider',{attrs:{"name":"department_color","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"color","id":"department_color","state":errors.length > 0 ? false : null,"name":"department_color","placeholder":"Enter Your Department Color"},model:{value:(_vm.form.department_color),callback:function ($$v) {_vm.$set(_vm.form, "department_color", $$v)},expression:"form.department_color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"border rounded p-1 mb-1"},[_c('div',{staticClass:"w-100 text-center"},[_c('b-img',{staticClass:"rounded mr-2 mb-1 mb-md-0 text-center",attrs:{"src":_vm.mentors_icon,"height":"60","width":"60"}})],1),_c('b-form-group',{attrs:{"label-for":"mentors_icon"}},[_c('b-form-file',{staticClass:"mt-1",attrs:{"id":"mentors_icon","accept":".jpg, .png, .gif","placeholder":"Select Mentors Icon","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.mentorsIcon($event)}}})],1),_c('b-form-group',{attrs:{"label":"Mentors Title","label-for":"mentors_title"}},[_c('validation-provider',{attrs:{"name":"mentors_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mentors_title","state":errors.length > 0 ? false : null,"name":"mentors_title","placeholder":"Enter Your Mentors Title"},model:{value:(_vm.form.mentors_title),callback:function ($$v) {_vm.$set(_vm.form, "mentors_title", $$v)},expression:"form.mentors_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Mentors Number","label-for":"mentors_qty"}},[_c('validation-provider',{attrs:{"name":"mentors_qty","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mentors_qty","state":errors.length > 0 ? false : null,"name":"mentors_qty","placeholder":"Enter Your Mentors Number"},model:{value:(_vm.form.mentors_qty),callback:function ($$v) {_vm.$set(_vm.form, "mentors_qty", $$v)},expression:"form.mentors_qty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Mentors Color","label-for":"mentors_color"}},[_c('validation-provider',{attrs:{"name":"mentors_color","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"color","id":"mentors_color","state":errors.length > 0 ? false : null,"name":"mentors_color","placeholder":"Enter Your Mentors Color"},model:{value:(_vm.form.mentors_color),callback:function ($$v) {_vm.$set(_vm.form, "mentors_color", $$v)},expression:"form.mentors_color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"border rounded p-1 mb-1"},[_c('div',{staticClass:"w-100 text-center"},[_c('b-img',{staticClass:"rounded mr-2 mb-1 mb-md-0 text-center",attrs:{"src":_vm.support_icon,"height":"60","width":"60"}})],1),_c('b-form-group',{attrs:{"label-for":"support_icon"}},[_c('b-form-file',{staticClass:"mt-1",attrs:{"id":"support_icon","accept":".jpg, .png, .gif","placeholder":"Select Support Icon","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.supportIcon($event)}}})],1),_c('b-form-group',{attrs:{"label":"Support Title","label-for":"support_title"}},[_c('validation-provider',{attrs:{"name":"support_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"support_title","state":errors.length > 0 ? false : null,"name":"support_title","placeholder":"Enter Your Support Title"},model:{value:(_vm.form.support_title),callback:function ($$v) {_vm.$set(_vm.form, "support_title", $$v)},expression:"form.support_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Support Number","label-for":"support_qty"}},[_c('validation-provider',{attrs:{"name":"support_qty","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"support_qty","state":errors.length > 0 ? false : null,"name":"support_qty","placeholder":"Enter Your Support Number"},model:{value:(_vm.form.support_qty),callback:function ($$v) {_vm.$set(_vm.form, "support_qty", $$v)},expression:"form.support_qty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Support Color","label-for":"support_color"}},[_c('validation-provider',{attrs:{"name":"support_color","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"color","id":"support_color","state":errors.length > 0 ? false : null,"name":"support_color","placeholder":"Enter Your Support Color"},model:{value:(_vm.form.support_color),callback:function ($$v) {_vm.$set(_vm.form, "support_color", $$v)},expression:"form.support_color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])]),_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(" Update ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }